import React, {useCallback, useEffect, useState} from "react";

export type UseInputHook = (validation: (value: string) => boolean, value?: string) => {
    enteredValue: string;
    enteredValueState: any,
    isTouched : any,
    validationInput: any,
    onChangeHandler: any,
    onBlurHandler: any,
    inValidationTest: any,
}

const useInput: UseInputHook  = (validation, value = '') => {
    const [enteredValue, setEnteredValue] = useState(value);
    const [isTouched, setIsTouched] = useState<boolean>(false);

    const validationInput = validation(enteredValue)
    const inValidationTest = !validationInput && isTouched;
    //console.log(inValidationTest);

    useEffect(() => {
        setEnteredValue(value);
    }, [value])

    const onChangeHandler = useCallback((event: any) => {
        setEnteredValue(event.target.value);
        //console.log(event.target.value);
        setIsTouched(true);
    }, []);


    const onBlurHandler = useCallback(() => {
        //console.log("rausgeklickt");
        setIsTouched(true);
    }, []);


    return {
        enteredValue,
        enteredValueState: setEnteredValue,
        isTouched : setIsTouched,
        validationInput,
        onChangeHandler,
        onBlurHandler,
        inValidationTest
    }
}

export default useInput;
