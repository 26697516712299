import React from "react";
import classes from "./Restaurant.module.scss";
import {Restaurant} from "../../interfaces/Restaurant";
import useHttpRequest from "../../hooks/restaurantHook";

import {useDispatch} from "react-redux";
import {idActions} from "../../store";
import {Link} from "react-router-dom";
import {ENDPOINTS} from "../../api/constants";

const Restaurants: React.FC<{restaurant: Restaurant, className?: string}> = ({ restaurant, className}) => {
    const {sendRequest: deleteRequest, error} = useHttpRequest();

    const dispatch = useDispatch();

    //da das ja die Komponente für jedes einzelnes Restaurant ist, hab ich über die props ja die ID!
    function onDeleteHandler() {
        deleteRequest({
            url: `${ENDPOINTS.restaurants}/${restaurant.id}`,
            method: "DELETE"})
        //die ID wird in Mampfkultur.tsx aufgerufen um dann den restaurantState zu aktualisieren
        dispatch(idActions.safeId(restaurant.id));
    }

    return (
        <>
            <div className={`${classes.restaurant} ${className ? classes[className] : ""}`}>
                <h4>{restaurant.name}</h4>
                <p>{restaurant.type}</p>
                <div className={classes.hoverRestaurant}>
                    <Link className={classes.update} to={'/restaurant/' + restaurant.id}>Bearbeiten</Link>
                    <p className={classes.delete} onClick={onDeleteHandler}>Löschen</p>
                </div>
            </div>
        </>
    )
}

export default Restaurants;
