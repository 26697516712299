import React, {useEffect, useState} from "react";
import useInput from "../../hooks/inputHook";
import useHttpRequest from "../../hooks/restaurantHook";
import classes from "./RestaurantAdd.module.scss"
import {Restaurant} from "../../interfaces/Restaurant";
import {ENDPOINTS} from "../../api/constants";

const RestaurantAdd: React.FC<{onAddRestaurant: (restaurant: Restaurant) => void}> = (props) => {

    // const [formInput, setFormInput] = useState<Array<{ name: string, foodOffer: string }>>([]);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);

    const {sendRequest: restaurantRequest, error:  restaurantError} = useHttpRequest();


    const {
        enteredValue: enteredRestaurantValue,
        enteredValueState: enteredRestaurantValueState,
        isTouched: isRestaurantTouched,
        validationInput: validationRestaurantInput,
        onChangeHandler: onChangeRestaurantHandler,
        onBlurHandler: onBlurRestaurantHandler,
        inValidationTest: inValidationRestaurantTest
    }=useInput((value: string) => value.trim() !== "");

    const {
        enteredValue: enteredSpeisenValue,
        enteredValueState: enteredSpeisenValueState,
        isTouched: isSpeisenTouched,
        validationInput: validationSpeisenInput,
        onChangeHandler: onChangeSpeisenHandler,
        onBlurHandler: onBlurSpeisenHandler,
        inValidationTest: inValidationSpeisenTest
    }=useInput((value: string) => value.trim() !== "");

    function formValidation() {
        if(!validationRestaurantInput || !validationSpeisenInput) {
            setIsFormValid(false);
        } else {
            setIsFormValid(true);
        }
    }

    useEffect(() => {
        let timeout = setTimeout(() => {
            formValidation();
        }, 500)
        return () => {
            clearTimeout(timeout);
        }
    }, [enteredRestaurantValue, enteredSpeisenValue])

    function newRestaurant(data: Restaurant) {
        console.log(data);
        props.onAddRestaurant(data);
    }

    function submitHandler(event: any) {
        event.preventDefault();

        isRestaurantTouched(false);
        enteredRestaurantValueState("");
        isSpeisenTouched(false);
        enteredSpeisenValueState("");

        const restaurant: Restaurant = {
            name: enteredRestaurantValue,
            type: enteredSpeisenValue
        }

        restaurantRequest({
            url: ENDPOINTS.restaurants,
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(restaurant)
        }, newRestaurant)
    }

    return (
        <div className={classes.restaurantAdd}>
            <h3>Restaurant hinzufügen:</h3>
            <form onSubmit={submitHandler}>
                <div className={classes.restaurantField}>
                    <label htmlFor='restauant'>Restaurant</label>
                    <input className={inValidationRestaurantTest ? classes.invalid : classes.valid}
                        type="text"
                        id="restaurant"
                        value={enteredRestaurantValue}
                        onChange={onChangeRestaurantHandler}
                        onBlur={onBlurRestaurantHandler}
                    />
                    {inValidationRestaurantTest && <p className={classes.invalid}>Bitte gib ein Restaurant ein</p>}
                </div>
                <div className={classes.speisenField}>
                    <label htmlFor='speisenangebot'>Speisenangebot</label>
                    <input className={inValidationSpeisenTest ? classes.invalid : classes.valid}
                        type="text"
                        id="speisenanbgebot"
                        value={enteredSpeisenValue}
                        onChange={onChangeSpeisenHandler}
                        onBlur={onBlurSpeisenHandler}
                    />
                    {inValidationSpeisenTest && <p className={classes.invalid}>Bitte gib das Speisenangebot ein</p>}
                </div>
                <button disabled={!isFormValid}>speichern</button>
                <p>{restaurantError}</p>
            </form>
        </div>
    )
}

export default RestaurantAdd;
