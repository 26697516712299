import useHttpRequest from "../hooks/restaurantHook";
import {useDispatch, useSelector} from "react-redux";
import {Restaurant} from "../interfaces/Restaurant";
import {completeRestaurantActions} from "../store";
import React, {useCallback, useEffect} from "react";
import Header from "../components/Header/Header";
import classes from "../App.module.scss";
import Miesmuschel from "../components/Miesmuschel/Miesmuschel";
import RestaurantAdd from "../components/RestaurantAdd/RestaurantAdd";
import Restaurants from "../components/Restaurants/Restaurants";
import Footer from "../components/Footer/Footer";
import {ENDPOINTS} from "../api/constants";

export const Mampfkultur = () => {
    //const [restaurants, setRestaurants] = useState<Array<Restaurant>>([]);
    const {sendRequest: getRestaurants, error: restaurantError} = useHttpRequest();

    const dispatch = useDispatch();
    const restaurantId = useSelector((state: {id?: {id: string | undefined}}) => state.id?.id);
    const restaurants = useSelector((state: {completeRestaurants : { restaurants: Restaurant[] }}) => state.completeRestaurants.restaurants);

    function restaurantHandler(restaurant: Restaurant) {
        //setRestaurants([restaurant, ...restaurants]);
        dispatch(completeRestaurantActions.safeNewRestaurant(restaurant));
    }


    //restaurantHook beim Mounten
    function showRestaurants(data: { restaurants: Restaurant[] }) {
        //setRestaurants(data.restaurants);
        console.log(data.restaurants);
        dispatch(completeRestaurantActions.safeCompleteRestaurants(data.restaurants));
        console.log(restaurants);
    }

    useEffect(() => {
        void getRestaurants({url: ENDPOINTS.restaurants}, showRestaurants)
    }, []);


    //wenn man auf löschen klickt dann wird das jeweilige Restaurant ausm State gefiltert
    const refreshRestaurants = useCallback(() => {
        if (restaurantId !== "") {
            const newRestaurants = restaurants.filter((restaurant) => restaurant.id !== restaurantId);
            //setRestaurants(newRestaurants);
            dispatch(completeRestaurantActions.safeDeleteRestaurants(newRestaurants));
        }
    }, [restaurantId]);

    useEffect(() => {
        refreshRestaurants();
    }, [restaurantId, refreshRestaurants]);


    return (
        <div className="App">
            <Header/>
            <main>
                <div className={classes.wrapMiesAdd}>
                    <Miesmuschel/>
                    <RestaurantAdd onAddRestaurant={restaurantHandler}/>
                </div>
                <div className={classes.restaurantsSection}>
                    <h3>Restaurants</h3>
                    <div className={classes.wrapRestaurants}>
                        {!restaurantError && restaurants && restaurants?.map((restaurant) => (
                            <Restaurants key={restaurant?.id ?? ''} restaurant={restaurant}/>
                        ))}
                    </div>
                </div>
            </main>
            <Footer/>
        </div>
    );
}
