import {configureStore, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Restaurant} from "../interfaces/Restaurant";

const initialIdState: {id: string | undefined} = {
    id: "",
}

const idSlice = createSlice({
    name: "id",
    initialState: initialIdState,
    reducers: {
        safeId(state, action: PayloadAction<string | undefined>) {
            state.id = action.payload;
            console.log(action.payload);
        },
    }
})


//const initialRestaurantState: Restaurant[] = [];
const initialRestaurantState: {restaurants: Restaurant[]} = {
    restaurants: [],
}

const completeRestaurantSlice = createSlice({
    name: "completeRestaurants",
    initialState: initialRestaurantState,
    reducers: {
        safeCompleteRestaurants(state, action: PayloadAction<Restaurant[]>) {
            state.restaurants = action.payload;
        },

        safeDeleteRestaurants(state, action: PayloadAction<Restaurant[]>) {
            state.restaurants = action.payload;
        },

        safeNewRestaurant(state, action: PayloadAction<Restaurant>) {
            state.restaurants = [action.payload, ...state.restaurants];
        }
    }
})

const store = configureStore({
    reducer: {
        [idSlice.name]: idSlice.reducer,
        [completeRestaurantSlice.name]: completeRestaurantSlice.reducer
    }
})

export const completeRestaurantActions = completeRestaurantSlice.actions;
export const idActions = idSlice.actions;
export default store;
