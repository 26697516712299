import classes from "./styles.module.scss";
import React from "react";

interface InputProps {
    label: string;
    value: string;
    errorText?: string;
    isValid: boolean;
    onBlur: () => void;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
}
export const Input: React.FC<InputProps> = ({
    label,
    value,
    isValid,
    onBlur,
    onChange,
    errorText = '',
}: InputProps) => {
    return (
        <div className={classes.restaurantField}>
            <label htmlFor='restauant'>{label}</label>
            <input className={isValid ? classes.invalid : classes.valid}
                   type="text"
                   id="restaurant"
                   value={value}
                   onChange={onChange}
                   onBlur={onBlur}
            />
            {isValid && <p className={classes.invalid}>{errorText}</p>}
        </div>
    )
}