import React from 'react';

import {Provider} from "react-redux";
import store from "./store";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {RestaurantUpdate} from "./pages/RestaurantUpdate";
import {Mampfkultur} from "./pages/Mampfkultur";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Mampfkultur />
    },
    {
        path: "restaurant/:id",
        element: <RestaurantUpdate />,
    },

]);


export default function App() {
   return (
       <Provider store={store}>
           <React.StrictMode>
               <RouterProvider router={router} />
           </React.StrictMode>
       </Provider>
   )
}

