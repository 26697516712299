import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Restaurant} from "../interfaces/Restaurant";
import {Input} from "../components/Input/Input";
import useInput from "../hooks/inputHook";
import useHttpRequest from "../hooks/restaurantHook";
import classes from "../components/Input/styles.module.css";
import {ENDPOINTS} from "../api/constants";

export function RestaurantUpdate() {
	const { id = '' } = useParams();
	const navigate = useNavigate();

	const [restaurant, setRestaurant] = useState<Restaurant>();
	const [loading, setLoading] = useState(true);
	const [valide, setValide] = useState<boolean>(false);

	const {
		enteredValue: enteredRestaurantValue,
		onChangeHandler: onChangeRestaurantHandler,
		onBlurHandler: onBlurRestaurantHandler,
		inValidationTest: invalidationRestaurantInput
	}=useInput((value: string) => value.trim() !== "", restaurant?.name);

	const {
		enteredValue: restaurantType,
		onChangeHandler: onChangeRestaurantTypeHandler,
		onBlurHandler: onBlurRestaurantTypeHandler,
		inValidationTest: invalidationSpeisenInput
	}=useInput((value: string) => value.trim() !== "", restaurant?.type);

	const rh = useHttpRequest()

	//da hol ich mir über die ID der URL, vom Server das entsprechende Restaurant und speicher es im State
	useEffect(() => {
		const request = () => {
			fetch(`${ENDPOINTS.restaurants}/${id}`)
				.then((res) => {
					//http status codes sagen dir viel ûber die request z.b. 200 alles ok + body
					if (res.status === 200) {
						return res.json()
					}
				}).then((data) => {
					setRestaurant(data);
					setLoading(false);
			})
		}

		request();
	}, [])


	const update = () => {
		 const body = {
			 name: enteredRestaurantValue,
			 type: restaurantType,
		 }

		 rh.sendRequest({
			 url: `${ENDPOINTS.restaurants}/${id}`,
			 method: 'PATCH',
			 body: JSON.stringify(body)
		 }, (res: Restaurant) => {
			 setRestaurant(res);
			 navigate('/') //dadurch kommt man wieder auf die Hauptseite
		 })
	}
	function formValidation() {
		if(invalidationRestaurantInput || invalidationSpeisenInput) {
			setValide(false);
		} else {
			setValide(true);
		}
	}

	useEffect(() => {
		let timeout = setTimeout(() => {
			formValidation();
		}, 500)
		return () => {
			clearTimeout(timeout);
		}
	}, [enteredRestaurantValue, restaurantType])

	if (loading || !restaurant) return null;

	return (
		<>
			<div className={classes.wrapRestaurantUpdate}>
				<Input label={'Restaurant'}
					   value={enteredRestaurantValue}
					   onChange={onChangeRestaurantHandler}
					   onBlur={onBlurRestaurantHandler}
					   isValid={invalidationRestaurantInput}
				/>
				<Input label={'Speisenangebot'}
					   value={restaurantType}
					   onChange={onChangeRestaurantTypeHandler}
					   onBlur={onBlurRestaurantTypeHandler}
					   isValid={invalidationSpeisenInput}
				/>
				<button disabled={!valide} onClick={update}>Speichern</button>
			</div>
		</>
	)
}
