import {useEffect, useState} from "react";
import useHttpRequest from "../../hooks/restaurantHook";
import Restaurants from "../Restaurants/Restaurants";
import {Restaurant} from "../../interfaces/Restaurant";
import {ENDPOINTS} from "../../api/constants";

function Miesmuschel() {
    const [miesmuschel, setMiesmuschel] = useState<Restaurant>();
    const [miesmuschelClicked, setMiesmuschelClicked] = useState<boolean>(false);

    const {sendRequest: restaurantRequest, error:  restaurantError} = useHttpRequest();
    const {sendRequest: miesmuschelRequest, error:  miesmuschelError} = useHttpRequest();

    //beim Mounten abfragen, ob Miesmuschel schonmal geklickt wurde
    function miesmuschelBoolean(data: {choosen: boolean, restaurant: Restaurant }) {
        console.log(data);
        if (data.choosen) {
            setMiesmuschelClicked(true);
            restaurantRequest({url: ENDPOINTS.miesmuschel, method: "POST"}, miesmuschelHandler);
        }
    }

    useEffect(() => {
        miesmuschelRequest({url: ENDPOINTS.miesmuschel}, miesmuschelBoolean);
    }, [])


    // Daten der Miesmuschel holen und im State speichern
    function miesmuschelHandler(data: Restaurant) {
        console.log(data);
        setMiesmuschel(data);
    }

    function miesmuschelClick() {
        restaurantRequest({url: ENDPOINTS.miesmuschel, method: "POST"}, miesmuschelHandler);
    }


    return (
        <div className="miesmuschel">
            <h3>Das gibt's heute:</h3>
            {!miesmuschelClicked && <button onClick={miesmuschelClick}>frag die magische<br/> MIESMUSCHEL</button>}
            {miesmuschel && <Restaurants className="miesmuschelRestaurant" restaurant={miesmuschel}/>}
            <p>{restaurantError}</p>
        </div>
    )
}

export default Miesmuschel;
