import pointsHeader from "../../assets/img/punkte.svg";
import semicircleOrange from "../../assets/img/halbkreis-orange.svg";
import schnitzel from "../../assets/img/schnitzel.png";
import semicircleYellow from "../../assets/img/kreis-gelb.svg";
import semicircleBlack from "../../assets/img/kreise.svg"

import classes from "./Header.module.scss";

function Header() {
    return (
        <header>
            <div className={classes.wrapHeadliners}>
                <h1>MAMPF<br></br>KULTUR</h1>
                <h2>was gibt's zum mampfen?</h2>
            </div>
            <img className={classes.headerPoints} src={pointsHeader} alt="Punkte"/>
            <div className={classes.wrapSchnitzel}>
                <img src={semicircleOrange} alt="Halbkreis Orange"/>
                <img src={schnitzel} alt="Schnitzel"/>
            </div>
            <div className={classes.circleHeader}>
                <img src={semicircleYellow} alt="Halbkreis Gelb"/>
                <img src={semicircleBlack} alt="Halbkreis Schwarz"/>
            </div>
        </header>
    )
}

export default Header;