import {useState} from "react";

function useHttpRequest() {

    const [error, setError] = useState(false);

    const sendRequest: <T>(requestURL: { url: string; method?: string; headers?: {}, body?: any},
                           transformData?: (data: T) => void) => void = async (requestURL, transformData) => {
        setError(false);
        try {
            const response = await fetch(requestURL.url, {
                method: requestURL.method ? requestURL.method : "GET",
                headers: requestURL.headers ? requestURL.headers : {},
                body: requestURL.body ? requestURL.body : null
            })

            if (!response.ok) {
                throw new Error("Request failed");
            }

            const getData = await response.json();
            console.log(getData);

            if(transformData) {
                transformData(getData);
            }

        } catch (err: any) {
            setError(err.message);
        }
    }

    return {
        sendRequest,
        error
    }
}

export default useHttpRequest;