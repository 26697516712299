import spaghetti from "../../assets/img/spaghetti.png";
import circleYellow from "../../assets/img/kreis-gelb.svg";
import circleBlack from "../../assets/img/kreise.svg";
import points from "../../assets/img/punkte-footer.svg";

import classes from "./Footer.module.scss"

function Footer() {
    return (
        <footer>
            <div className={classes.wrapPic}>
                <img src={spaghetti}/>
                <img src={circleYellow}/>
                <img src={circleBlack}/>
            </div>
            <img src={points}/>
        </footer>
    )
}

export default Footer;
